define(['cookie'], function(cookie) {

  const oneTrustSilentHelper = () => {

    const component = {
      attempts: 0
    };

    component.getReadyState = () => {
      return document.readyState;
    };

    component.init = () => {
      if (component.getReadyState() === 'complete') {
        component.addClickListenersToAcceptButtons();
      } else {
        window.addEventListener('load', component.addClickListenersToAcceptButtons);
      }

    };

    component.addSilentLoadListener = (element) => {
      element.addEventListener('click', component.silentLoad);
    };

    component.silentLoad = () => {
      let disallowedQueryParameters = ['buy'];

      if (window.location.href.indexOf('affil=') !== -1) {
        let queryBreak = window.location.href.indexOf('?');
        let schemeDomainPath = window.location.href.substring(0, queryBreak);
        let queryParameters = window.location.href.substring(queryBreak + 1, window.location.href.length).split('&');

        let newLocation = schemeDomainPath + '?';
        queryParameters.forEach((param, index) => {
          const paramSplit = param.split('=')
          if (disallowedQueryParameters.indexOf(paramSplit[0]) === -1) {
            newLocation += (index !== 0 ? '&' : '') + param
          }
        })
        setTimeout(function() {
          window.fetch(newLocation, {method: 'GET', cache: 'no-cache', credentials: 'include'});
        }, 1000);
      }
    };


    component.addClickListenersToAcceptButtons = () => {
      let OABCcookieName = 'OptanonAlertBoxClosed';
      let bannerAcceptBtn = document.getElementById('onetrust-accept-btn-handler');
      let pcAllowAllBtn = document.getElementById('accept-recommended-btn-handler');
      let pcSaveBtn = document.getElementsByClassName('save-preference-btn-handler onetrust-close-btn-handler')[0];
      let OABCcookie = cookie.get(OABCcookieName);

      if (!OABCcookie && bannerAcceptBtn && pcAllowAllBtn && pcSaveBtn) {
        component.addSilentLoadListener(bannerAcceptBtn);
        component.addSilentLoadListener(pcAllowAllBtn);
        component.addSilentLoadListener(pcSaveBtn);
        return;
      }

      if (!OABCcookie) {
        component.attempts += 1;
        if (component.attempts > 30) {
          return;
        }

        setTimeout(component.addClickListenersToAcceptButtons, 100);
      }

    };

    return component;
  };

  return oneTrustSilentHelper;
});
